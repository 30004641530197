.bg-myRed{
    background-color: crimson;
  }
  
  .content{
    padding: 12px;
    height: 3000px;
  }
  
  .coloring{
    color: black;
  }